@mixin transition($time) {
	-webkit-transition: all $time ease-out 0s;
	-moz-transition: all $time ease-out 0s;
	-ms-transition: all $time ease-out 0s;
	-o-transition: all $time ease-out 0s;
	transition: all $time ease-out 0s;
}

@mixin transform($value) {
  -webkit-transform: $value;
      -ms-transform: $value;
          transform: $value;
}